<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="700" :centered="true">
    <template slot="footer">
      <a-button v-if="showType=='add'||showType=='edit'" @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <a-form-model ref="modalForm" :model="formDatas" :rules="formRules" :label-col="{span:9}" :wrapper-col="{span:14}">
      <a-form-model-item label="电梯注册码" prop="liftid">
        <a-input :value="liftItem?liftItem.liftnum:''" style="width: 65%;" disabled />
        <a-button type="primary" style="width: 30%;margin-left:5%;" @click="selectLiftVisible=true">选择</a-button>
      </a-form-model-item>
      <a-form-model-item label="保养类型" prop="maintenancescheduletype">
        <a-select v-model="formDatas.maintenancescheduletype">
          <a-select-option v-for="(item, index) in maintenanceTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <template v-if="formDatas.maintenancescheduletype && formDatas.maintenancescheduletype.toString().indexOf('5') === 0">
        <!-- <a-form-model-item label="按需保养类别" prop="maintenanceondemandtype">
          <a-select v-model="formDatas.maintenanceondemandtype">
            <a-select-option v-for="(item, index) in maintenanceTypesNeed" :key="index" :value="item.value">{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item> -->
        <a-form-model-item label="是否有企业自我声明" prop="selfdeclaration">
          <a-switch v-model="formDatas.selfdeclaration" checked-children="是" un-checked-children="否" default-checked />
        </a-form-model-item>
        <a-form-model-item label="是否安装了电梯物联网数据采集设备" prop="datacollecttion">
          <a-switch v-model="formDatas.datacollecttion" checked-children="是" un-checked-children="否" default-checked />
        </a-form-model-item>
        <a-form-model-item label="电梯保险类型" prop="insurancetype">
          <a-input v-model="formDatas.insurancetype" />
        </a-form-model-item>
        <a-form-model-item label="保险单号" prop="insurancenum">
          <a-input v-model="formDatas.insurancenum" />
        </a-form-model-item>
      </template>
      <a-form-model-item label="首次保养时间" prop="starttime">
        <a-date-picker v-model="starttimeMoment" style="width: 100%;"></a-date-picker>
      </a-form-model-item>
      <a-form-model-item label="计划状态" prop="status">
        <a-radio-group v-model="formDatas.status">
          <a-radio :value="1">启动</a-radio>
          <a-radio :value="2">停止</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="描述" prop="scheduledesc">
        <a-textarea v-model.trim="formDatas.scheduledesc" />
      </a-form-model-item>
    </a-form-model>
    <select-lift select-type="radio" :visible.sync="selectLiftVisible" :default-selected="liftItem?[liftItem]:[]" @get-selected-rows="getLiftSelectRows"></select-lift>
  </a-modal>
</template>
<script>
import moment from 'moment'
import SelectLift from 'C/modals/SelectLift.vue'
// import maintenanceTypes from '@/json/maintenanceTypes'
import maintenanceTypesNeed from '@/json/maintenanceTypesNeed'
import { addMaintenanceschedule, modifyMaintenanceschedule } from 'A/wbgl.js'
import { getDictByDicType } from 'A/xtpz'
export default {
  components: {
    SelectLift,
  },
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      maintenanceTypes: [],
      maintenanceTypesNeed,
      modalVisible: false,
      formDatas: {
        maintenancescheduleid: '',
        liftid: '',
        maintenancescheduletype: '',
        // maintenanceondemandtype: '',
        selfdeclaration: true,
        datacollecttion: true,
        insurancetype: '',
        insurancenum: '',
        starttime: '',
        status: 1,
        scheduledesc: '',
      },
      formRules: {
        liftid: [{required: true, message: '请选择电梯'}],
        maintenancescheduletype: [{required: true, message: '请选择保养类型'}],
        // maintenanceondemandtype: [{required: true, message: '请选择按需保养类别'}],
        insurancetype: [{required: true, message: '请输入保险类型'}],
        insurancenum: [{required: true, message: '请输入保险单号'}],
        starttime: [{required: true, message: '请选择首次保养时间'}],
        status: [{required: true, message: '请选择计划状态'}],
      },
      selectLiftVisible: false,
      liftItem: null,
      starttimeMoment: null,
    }
  },
  computed: {
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    },
    liftItem(val) {
      if(val) {
        this.formDatas.liftid = val.liftid;
      }else {
        this.formDatas.liftid = '';
      }
    },
    starttimeMoment(val) {
      if(val) {
        this.formDatas.starttime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm && this.$refs.modalForm.clearValidate('starttime');
      }else {
        this.formDatas.starttime = '';
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
    this.initMaintenanceTypes();
  },
  methods: {
    initMaintenanceTypes() {
      let params = {
        dictype: '15',
      }
      getDictByDicType(params).then(res => {
        if(res && res.returncode == '0') {
          this.maintenanceTypes = res.item;
        }
      })
    },
    initDetail() {
      if(this.detailData && (this.showType == 'edit' || this.showType == 'detail')) {
        if(this.detailData.starttime) {
          this.starttimeMoment = moment(this.detailData.starttime, 'YYYYMMDD')
        }
        if(this.detailData.liftid) {
          this.liftItem = {
            liftid: this.detailData.liftid,
            liftnum: this.detailData.liftnum,
          }
        }
        this.$nextTick(() => {
          for(let key in this.formDatas) {
            if(key == 'selfdeclaration' || key == 'datacollecttion') {
              this.formDatas[key] = this.detailData[key]=='1'?true:false;
            }else if(key == 'maintenancescheduletype') {
              this.formDatas[key] = ''+this.detailData[key];
            }else {
              this.formDatas[key] = this.detailData[key];
            }
          }
        })
      }
    },
    resetDatas() {
      this.formDatas.maintenancescheduleid = '';
      this.liftItem = null;
      this.starttimeMoment = null;
      this.$refs.modalForm.clearValidate();
      for(let key in this.formDatas) {
        if(key == 'status') {
          this.formDatas[key] = 1;
        }else {
          this.formDatas[key] = '';
        }
      }
    },
    confirm() {
      // success fail
      if(this.showType == 'add' || this.showType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if(valid) {
            this.showLoading();
            let params = {
              ...this.formDatas
            };
            params.selfdeclaration = params.selfdeclaration ? '1' : '0';
            params.datacollecttion = params.datacollecttion ? '1' : '0';
            if(this.showType == 'add') {
              addMaintenanceschedule(params).then(res => {
                this.hideLoading();
                if(res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                }else {
                  this.$message.error(res.errormsg||'操作失败');
                }
              })
            }else {
              modifyMaintenanceschedule(params).then(res => {
                this.hideLoading();
                if(res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                }else {
                  this.$message.error(res.errormsg||'操作失败');
                }
              })
            }
          }else {
            return false;
          }
        })
      }else {
        this.modalVisible = false;
      }
    },
    getLiftSelectRows(rows) {
      this.liftItem = rows[0]
    },
  }
}
</script>