<template>
  <page-container title="保养计划" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="维保类型" prop="maintenancescheduletype">
              <a-select v-model="queryParams.maintenancescheduletype">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option v-for="(item, index) in maintenanceTypes" :key="index" :value="item.value">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="计划状态" prop="status">
              <a-select v-model="queryParams.status">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option v-for="(item, index) in scheduleStatuss" :key="index" :value="item.value">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
<!--            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='3'" label="维保单位" prop="maintenancedepid">-->
<!--              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions" v-model="maintenancedepidCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择维护单位" :allow-clear="false"></a-cascader>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='3'" label="维保组" prop="maintenancegroupid">-->
<!--              <a-select v-model="queryParams.maintenancegroupid">-->
<!--                <a-select-option v-for="(item, index) in maintenancegroupidOptions" :key="index" :value="item.maintenancegroupid">{{item.maintenancegroupname}}</a-select-option>-->
<!--              </a-select>-->
<!--            </a-form-model-item>-->
            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='3'" label="维保单位" prop="maintenancedepname">
              <a-input v-model="queryParams.maintenancedepname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='3'" label="维保组" prop="maintenancegroupname">
              <a-input v-model="queryParams.maintenancegroupname" placeholder="请输入"></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">保养计划</h3>
          <div class="table-btns">
            <a-button @click="showModal('export')" style="margin-right: 10px"><a-icon type="export"></a-icon>导出</a-button>
            <a-button type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="maintenancescheduleid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="maintenancescheduletype" slot-scope="text">
            <!-- <a-tooltip :title="maintenanceTypesMap[text]||''"> -->
              <span :title="maintenanceTypesMap[text]||''">{{maintenanceTypesMap[text]||''}}</span>
            <!-- </a-tooltip> -->
          </span>
          <span slot="status" slot-scope="text">
            <span>{{scheduleStatusMap[text]||''}}</span>
          </span>
          <span slot="starttime" slot-scope="text">
            <!-- <a-tooltip :title="text ? moment(text, 'YYYYMMDD').format('YYYY-MM-DD') : ''"> -->
              <span :title="text ? moment(text, 'YYYYMMDD').format('YYYY-MM-DD') : ''">{{text ? moment(text, 'YYYYMMDD').format('YYYY-MM-DD') : ''}}</span>
            <!-- </a-tooltip> -->
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.maintenancescheduleid">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.maintenancescheduleid">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.maintenancescheduleid">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal v-model="exportModalVisible" title="导出" centered>
      <template slot="footer">
        <a-button @click="exportModalVisible=false">取消</a-button>
        <a-button type="primary" @click="confirm">确认</a-button>
      </template>
      <a-form-model ref="modalForm" :model="formDatas" :rules="formRules" :label-col="{span:8}" :wrapper-col="{span:12}">
        <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='1'" label="使用单位" prop="userdepid">
          <a-cascader :show-search="{ filter }" :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype=='1'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype=='1'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择使用单位" :change-on-select="true" :allow-clear="false"></a-cascader>
        </a-form-model-item>
        <a-form-model-item label="年份" prop="year">
          <a-select v-model="formDatas.year">
            <a-select-option v-for="(item, index) in yearOptions" :key="index" :value="item">{{item+'年'}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="月份" prop="month">
          <a-select v-model="formDatas.month">
            <a-select-option v-for="i in 12" :key="i" :value="i+''">{{i+'月'}}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
    <detail-modal :visible.sync="detailmodalVisible" :detailData="modalDetailData"></detail-modal>
  </page-container>
</template>
<script>
import { mapState } from 'vuex'
import moment from 'moment'
import store from '@/store'
import { getItemFromArrayByKey } from 'U'
import deptselect from '@/mixins/deptselect'
import pagination from '@/mixins/pagination'
import maintenanceTypes from '@/json/maintenanceTypes'
import { getMentenacegroupListByCondition } from 'A/xtpz.js'
import { findMaintenancescheduleListByCondition, deleteMaintenanceschedule } from 'A/wbgl.js'
import addOrEditModal from './addOrEditModal'
import DetailModal from './DetailModal'
import callCenter from "C/modals/callCenter";
import {exportMaintenanceScheduleExcel} from "A/wbgl";
export default {
  name: 'maintenanceSchedule',
  mixins: [deptselect, pagination],
  components: {
    addOrEditModal,
    DetailModal,
  },
  data() {
    return {
      showAdvanced: false,
      moment,
      maintenanceTypes,
      scheduleStatuss: [
        {
          value: '1',
          name: '启动'
        },
        {
          value: '2',
          name: '停止'
        },
      ],
      breadcrumb: [
        {
          name: '维保管理',
          path: ''
        },
        {
          name: '保养管理',
          path: ''
        },
        {
          name: '保养计划',
          path: ''
        },
      ],
      queryParams: {
        maintenancescheduletype: '',
        status: '',
        maintenancedepid: '',
        maintenancegroupid: '',
        maintenancedepname:'',
        maintenancegroupname:'',
      },
      maintenancedepidCascaderSelected: [],
      maintenancegroupidOptions: [],
      userdepidCascaderSelected:[],
      tableColumns: [
        {
          title: '电梯注册码',
          dataIndex: 'liftnum',
          key: 'liftnum',
          ellipsis: true,
        },
        {
          title: '安装地址',
          dataIndex: 'adress',
          key: 'adress',
          ellipsis: true,
        },
        {
          title: '维保单位',
            dataIndex: 'maintenancename',
          key: 'maintenancename',
          ellipsis: true,
        },
        {
          title: '维保组',
          dataIndex: 'maintenancegroupname',
          key: 'maintenancegroupname',
          ellipsis: true,
        },
        {
          title: '首次保养时间',
          dataIndex: 'starttime',
          key: 'starttime',
          ellipsis: true,
          scopedSlots: { customRender: 'starttime' }
        },
        {
          title: '计划状态',
          dataIndex: 'status',
          key: 'status',
          ellipsis: true,
          width: 80,
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '保养类型',
          dataIndex: 'maintenancescheduletype',
          key: 'maintenancescheduletype',
          ellipsis: true,
          width: 80,
          scopedSlots: { customRender: 'maintenancescheduletype' }
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 80,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      detailmodalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      exportModalVisible:false,
      yearOptions: [],
      formDatas:{
        userdepid:'',
        year:'',
        month:'',
      },
      formRules: {
        userdepid: [{required: true, message: '请选择使用单位'}],
        year: [{required: true, message: '请选择年份'}],
        month: [{required: true, message: '请选择月份'}],
      },
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    maintenanceTypesMap() {
      let result = {};
      this.maintenanceTypes.forEach(item => {
        result[item.value] = item.name;
      })
      return result;
    },
    scheduleStatusMap() {
      let result = {};
      this.scheduleStatuss.forEach(item => {
        result[item.value] = item.name;
      })
      return result;
    },
  },
  watch: {
    maintenancedepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.maintenancedepid = val[val.length-1];
      }else {
        this.queryParams.maintenancedepid = '';
      }
    },
    userdepidCascaderSelected(val) {
      if (val && val.length) {
        this.formDatas.userdepid = val[val.length - 1];
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    if(!toTab) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  created() {
    this.init();
    this.initDeptOptionsAll();
    this.getYearOptions();
  },
  methods: {
    filter(inputValue, path) {
      return path.some(option => option.userdepname.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },
    init() {
      this.getTableData();
      this.initMaintenancegroupidOptions();
    },
    initMaintenancegroupidOptions() {
      let params = {
        maintenancedepid: this.queryParams.maintenancedepid,
      }
      getMentenacegroupListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.maintenancegroupidOptions = res.item;
        }
      })
    },
    resetQueryParams() {
      this.maintenancedepidCascaderSelected = [];
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      findMaintenancescheduleListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    deleteConfirm(value, record) {
      this.$confirm({
        // title: '',
        content: '确定要删除该数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.delete(record.maintenancescheduleid);
        },
        onCancel() {},
      });
    },
    delete(maintenancescheduleid) {
      this.showLoading();
      if(maintenancescheduleid) {
        let params = {
          maintenancescheduleid
        };
        deleteMaintenanceschedule(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    showModal(type, value, record) {
      this.modalShowType = type;
      if(type == 'add') {
        this.modalDetailData = null;
      }else {
        this.modalDetailData = record;
      }
      if(type == 'detail') {
        this.detailmodalVisible = true;
      }else if(type == 'export') {
        this.exportModalVisible = true;
      }else {
        this.modalVisible = true;
      }
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let maintenancescheduleid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'maintenancescheduleid', maintenancescheduleid);
      if(type == 'detail') {
        this.showModal('detail', maintenancescheduleid, record);
      }else if(type == 'edit') {
        this.showModal('edit', maintenancescheduleid, record);
      }else if(type == 'delete') {
        this.deleteConfirm(maintenancescheduleid, record);
      }
    },
    confirm(){
      this.$refs.modalForm.validate(valid => {
        if (valid) {
          let params = {...this.formDatas}
          this.showLoading();
          exportMaintenanceScheduleExcel(params).then((res)=>{
            this.hideLoading();
            if(res&&res.returncode=="1"&&res.errormsg=="none"){
              this.$message.error("该使用单位在所选月份无保养计划")
            }else{
              this.exportModalVisible=false
              this.$message.success("导出成功")
            }
          }).catch(()=>{
            this.hideLoading();
          })
        }
      })
    },
    getYearOptions() {
      this.yearOptions = [];
      let currentYear = moment().year();
      for(let i=4; i>-1; i--) {
        this.yearOptions.push(currentYear-i+'');
      }
    },
  }
}
</script>
<style lang="scss" scoped>
</style>