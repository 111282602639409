<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="900" :body-style="{maxHeight: '560px',overflowY: 'auto'}" :centered="true">
    <template slot="footer">
      <a-button type="primary" @click="modalVisible=false">关闭</a-button>
    </template>
    <a-form-model ref="modalForm" :model="formDatas" layout="inline" class="form-in-twocolumns detail-form-bg" :label-col="{span:9}" :wrapper-col="{span:14}">
      <a-form-model-item label="电梯注册码" prop="liftnum">
        <span>{{formDatas.liftnum}}</span>
      </a-form-model-item>
      <a-form-model-item label="安装地址" prop="adress">
        <span>{{formDatas.adress}}</span>
      </a-form-model-item>
      <a-form-model-item label="保养类型" prop="maintenancescheduletype">
        <span>{{maintenanceTypesMap[formDatas.maintenancescheduletype] || ''}}</span>
      </a-form-model-item>
      <!-- <a-form-model-item v-if="formDatas.maintenancescheduletype && formDatas.maintenancescheduletype.indexOf('5') === 0" label="按需保养类别" prop="maintenanceondemandtype">
        <span>{{maintenanceTypesNeedMap[formDatas.maintenanceondemandtype] || ''}}</span>
      </a-form-model-item> -->
      <a-form-model-item label="首次保养时间" prop="starttime">
        <span>{{formDatas.starttime ? moment(formDatas.starttime).format('YYYY-MM-DD') : ''}}</span>
      </a-form-model-item>
      <template v-if="formDatas.maintenancescheduletype && formDatas.maintenancescheduletype.toString().indexOf('5') === 0">
        <a-form-model-item label="是否有企业自我声明" prop="selfdeclaration">
          <span>{{formDatas.selfdeclaration == '1' ? '是' : '否'}}</span>
        </a-form-model-item>
        <a-form-model-item label="是否安装了物联网数据采集设备" prop="datacollecttion" class="wrap-label">
          <span>{{formDatas.datacollecttion == '1' ? '是' : '否'}}</span>
        </a-form-model-item>
        <a-form-model-item label="电梯保险类型" prop="insurancetype">
          <span>{{formDatas.insurancetype}}</span>
        </a-form-model-item>
        <a-form-model-item label="保险单号" prop="insurancenum">
          <span>{{formDatas.insurancenum}}</span>
        </a-form-model-item>
      </template>
      <a-form-model-item label="维保单位" prop="maintenancename">
        <span>{{formDatas.maintenancename}}</span>
      </a-form-model-item>
      <a-form-model-item label="维保组" prop="maintenancegroupname">
        <span>{{formDatas.maintenancegroupname}}</span>
      </a-form-model-item>
      <a-form-model-item label="计划状态" prop="status">
        <span>{{formDatas.status=='1' ? '已启动' : '停止'}}</span>
      </a-form-model-item>
      <a-form-model-item label="描述" prop="scheduledesc">
        <span>{{formDatas.scheduledesc}}</span>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import moment from 'moment'
// import maintenanceTypes from '@/json/maintenanceTypes'
import maintenanceTypesNeed from '@/json/maintenanceTypesNeed'
import { getDictByDicType } from 'A/xtpz'
export default {
  props: {
    visible: {
      default: false
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      maintenanceTypes: [],
      maintenanceTypesNeed,
      modalVisible: false,
      formDatas: {
        liftid: '',
        maintenancescheduletype: '',
        maintenanceondemandtype: '',
        selfdeclaration: '1',
        datacollecttion: '1',
        insurancetype: '',
        insurancenum: '',
        starttime: '',
        status: 1,
        scheduledesc: '',
      },
    }
  },
  computed: {
    modalTitle() {
      return '详情'
    },
    maintenanceTypesMap() {
      let result = {};
      this.maintenanceTypes.forEach(item => {
        result[item.dickey] = item.dicvalue
      })
      return result;
    },
    maintenanceTypesNeedMap() {
      let result = {};
      this.maintenanceTypesNeed.forEach(item => {
        result[item.value] = item.name
      })
      return result;
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
    this.initMaintenanceTypes();
  },
  methods: {
    initMaintenanceTypes() {
      let params = {
        dictype: '15',
      }
      getDictByDicType(params).then(res => {
        if(res && res.returncode == '0') {
          this.maintenanceTypes = res.item;
        }
      })
    },
    initDetail() {
      if(this.detailData) {
        this.$nextTick(() => {
          this.formDatas = {
            ...this.formDatas,
            ...this.detailData
          };
        })
      }
    },
    resetDatas() {
      this.$refs.modalForm.resetFields();
    },
  }
}
</script>